<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('learn.catalog.overview')" class="catalog">
    <CCol cols="12" lg="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
              {{$t('learn.Catalog')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <loadingSpinner v-if="videosUploading.length > 0" mode="inline" :content="$t('upload.Uploading_videos')" class="uploading_videos"/>

              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <b-switch class="m-0"
                          v-model="showInactive"
                          size="is-small"
                          @input="filterCatalogData()">
                  {{ $t('common.show_inactive') }}
                </b-switch>
                <CButton v-if="checkPermission('learn.assigncourses')" class="d-inline-block ml-2 m-0" color="primary" @click="openSidebarRight('assign_courses', { lms_course: null })">
                  <i class="fas fa-user-plus mr-2"/>
                  <span>{{ $t('learn.Assign_courses') }}</span>
                </CButton>
              </div>
            </CCol>           
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow class="m-0">
            <CCol cols="3" lg="3" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span class="text-uppercase">
                      <b>{{$t('common.Categories')}}</b> 
                    </span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('learn.catalog.addcategory')" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="openSidebarRight('learn_category_details', { lms_course_category_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('common.Add_category')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !categoriesLoading}">
                    <div v-if="categoriesLoading">
                      <loadingSpinner mode="auto" v-if="categoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="categoriesTable"
                              class="data_table add_bottom_left_radius"
                              :data="categories"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="categoriesPaginated"
                              :per-page="categoriesPerPage"
                              :current-page.sync="categoriesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => row.lms_course_category_id_external === activeCategory.lms_course_category_id_external && 'active'">
                            
                            <template slot-scope="props">                      
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.category_image_id && props.row.from_marketplace === 'N'"
                                    class="item_image pointer"
                                    v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-category/' + props.row.lms_course_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }"
                                    @click="getCategoryCourses(props.row.course_category_name, props.row.lms_course_category_id_external, props.row.from_marketplace);">
                                </div>
                                <div v-else-if="props.row.category_image_id && props.row.from_marketplace === 'Y'"
                                     class="item_image pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-category-mp/' + props.row.lms_course_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }"
                                     @click="getCategoryCourses(props.row.course_category_name, props.row.lms_course_category_id_external, props.row.from_marketplace);">
                                </div>                                
                                <div v-else class="item_icon d-inline-block align-middle pointer" @click="getCategoryCourses(props.row.course_category_name, props.row.lms_course_category_id_external, props.row.from_marketplace);">
                                  <i class="fas fa-list"/>               
                                </div>         
                              </b-table-column>            
                              <b-table-column field="course_category_name" :label="$t('common.Category')" :searchable="searchEnabled">
                                <span class="pointer" @click="getCategoryCourses(props.row.course_category_name, props.row.lms_course_category_id_external, props.row.from_marketplace);">                        
                                  <b>{{props.row.course_category_name}}</b>
                                </span>
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_courses}} {{props.row.nr_of_courses !== 1 ? $t('common.courses') : $t('common.course')}}                                      
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_courses}} {{props.row.nr_of_active_courses !== 1 ? $t('common.courses') : $t('common.course')}}
                                  </span>
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                
                              </b-table-column>                                                            
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                  <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                      targetGroupsType="learn category"
                                                      :targetGroupsIdExternal="props.row.lms_course_category_id_external"
                                                      popoverPlacement="left"/>
                              </b-table-column>
                              <b-table-column field="action" width="10%" :visible="checkPermission('learn.catalog.editcategory')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary" 
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('common.Category_managed_by_owner')} : ''"
                                          @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('learn_category_details', { lms_course_category_id_external: props.row.lms_course_category_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_categories_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>            
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol cols="3" lg="3" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">                    
                    <span v-if="activeCategory.lms_course_category_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeCategory.course_category_name}}</b>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('lms.Courses')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('learn.catalog.addcourse') && activeCategory.lms_course_category_id_external && activeCategory.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link"
                               color="primary"
                               @click="openSidebarRight('learn_course_details', { lms_category: activeCategory, lms_course_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('common.Add_course')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeCategory.lms_course_category_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !coursesLoading}">
                    <div v-if="coursesLoading">
                      <loadingSpinner mode="auto" v-if="coursesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="coursesTable"
                              class="data_table includes_dropdown"
                              :data="courses"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="coursesPaginated"
                              :per-page="coursesPerPage"
                              :current-page.sync="coursesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => row.lms_course_id_external === activeCourse.lms_course_id_external && 'active'">
                            
                            <template slot-scope="props">                              
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.course_image_id && props.row.from_marketplace === 'N'"
                                     class="item_image d-inline-block align-middle pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken +')' }"
                                     @click="getCourseChapters(props.row.course_name, props.row.lms_course_id_external, props.row.from_marketplace);">
                                </div>
                                <div v-else-if="props.row.course_image_id && props.row.from_marketplace === 'Y'"
                                     class="item_image d-inline-block align-middle pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken +')' }"
                                     @click="getCourseChapters(props.row.course_name, props.row.lms_course_id_external, props.row.from_marketplace);">
                                </div>                                
                                <div v-else class="item_icon d-inline-block align-middle pointer" @click="getCourseChapters(props.row.course_name, props.row.lms_course_id_external, props.row.from_marketplace);">
                                  <i class="fas fa-list"/>               
                                </div>                     
                              </b-table-column>
                              <b-table-column field="course_name" :label="$t('lms.Course')" :searchable="searchEnabled">
                                <span class="pointer" @click="getCourseChapters(props.row.course_name, props.row.lms_course_id_external, props.row.from_marketplace);">
                                  <b>{{props.row.course_name}}</b>
                                </span>
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_chapters}} {{props.row.nr_of_chapters !== 1 ? $t('common.chapters') : $t('common.chapter')}}
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_chapters}} {{props.row.nr_of_active_chapters !== 1 ? $t('common.chapters') : $t('common.chapter')}}
                                  </span>
                                  <span v-if="props.row.duration">
                                    | {{props.row.duration}} {{props.row.duration !== 1 ? $t('common.minutes') : $t('common.minute')}}
                                  </span>
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                                                
                              </b-table-column>                  
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                    targetGroupsType="learn course"
                                                    :targetGroupsIdExternal="props.row.lms_course_id_external"
                                                    popoverPlacement="left"/>
                              </b-table-column>                  
                              <b-table-column field="action" width="10%">
                                <div class="d-flex justify-content-lg-end">
                                  <CDropdown v-if="checkPermission('learn.catalog.editcourse') || checkPermission('learn.assigncourses')"
                                             color="primary"
                                             toggler-text=""
                                             :caret="false"
                                             class="ml-2 table_actions_dropdown">
                                    <div slot="toggler-content">
                                      <span class="d-flex align-items-center">
                                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                      </span>
                                    </div>

                                    <CDropdownItem v-if="checkPermission('learn.catalog.editcourse')">
                                      <div class="d-flex align-items-center" 
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('learn.Course_managed_by_owner')} : ''"                                      
                                           @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('learn_course_details', { lms_category: props.row.category, lms_course_id_external: props.row.lms_course_id_external }) : null">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-pen"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('lms.edit_course')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                    <CDropdownItem v-if="checkPermission('learn.assigncourses')">
                                      <div class="d-flex align-items-center" @click="openSidebarRight('assign_courses', { lms_course: { lms_course_id_external: props.row.lms_course_id_external, course_name: props.row.course_name }} )">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-user-plus"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('learn.Assign_course')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                  </CDropdown>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_courses_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>          
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('learn.Select_category_for_courses')}}</span>
                  </CCol>
                </CRow>          
              </div>
            </CCol>

            <CCol cols="3" lg="3" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span v-if="activeCourse.lms_course_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeCourse.course_name}}</b>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('common.Items')}}</b></span>                    
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('learn.catalog.addchapter') && activeCourse.lms_course_id_external && activeCourse.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">                      
                      <CButton class="m-0 link"
                               color="primary"
                               @click="openSidebarRight('learn_chapter_details', { lms_course: activeCourse, lms_chapter_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('lms.Add_chapter')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeCourse.lms_course_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !chaptersLoading}">
                    <div v-if="chaptersLoading">
                      <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                    </div>
                    <div v-else>
                      <b-table ref="chaptersTable"
                              class="data_table"
                              :data="chapters"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="chaptersPaginated"
                              :per-page="chaptersPerPage"
                              :current-page.sync="chaptersPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              @dragstart="dragStartChapter"
                              @drop="dropChapter"
                              @dragover="dragOverChapter"
                              @dragleave="dragLeaveChapter"
                              :row-class="(row, index) => row.lms_chapter_id_external === activeChapter.lms_chapter_id_external && 'active'">
                            
                            <template slot-scope="props">
                              <b-table-column field="step_order" :label="$t('Order')" width="5%">
                                <span>
                                  <i v-if="activeCourse.from_marketplace === 'N'" class="fas fa-arrows-alt mr-2 pointer"/>{{props.row.chapter_order + 1}}
                                </span>
                              </b-table-column>                              
                              <b-table-column field="chapter_name" :label="$t('lms.Chapter')" :searchable="searchEnabled" class="break">
                                <span class="pointer" @click="getChapterSteps(props.row.chapter_name, props.row.lms_chapter_id_external, props.row.from_marketplace);">                        
                                  <b>{{props.row.chapter_name}}</b>
                                </span>
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_steps}} {{props.row.nr_of_steps !== 1 ? $t('common.steps') : $t('common.step')}}
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_steps}} {{props.row.nr_of_active_steps !== 1 ? $t('common.steps') : $t('common.step')}}
                                  </span>
                                  <span v-if="props.row.duration">
                                    | {{props.row.duration}} {{props.row.duration !== 1 ? $t('common.minutes') : $t('common.minute')}}
                                  </span>                                  
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                                                 
                              </b-table-column>
                              <b-table-column field="actions" :visible="checkPermission('learn.catalog.editchapter')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary" 
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('learn.Chapter_managed_by_owner')} : ''"
                                          @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('learn_chapter_details', { lms_course: props.row.course, lms_chapter_id_external: props.row.lms_chapter_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>                                  
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">                                                          
                                <span>{{$t('learn.no_chapters_found')}}</span>             
                              </div>                
                            </template> 
                      </b-table>
                    </div>
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('learn.Select_course_to_view_chapters')}}</span>
                  </CCol>
                </CRow>           
              </div>
            </CCol>  

            <CCol cols="3" lg="3" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span v-if="activeChapter.lms_chapter_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeChapter.chapter_name}}</b>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('common.Steps')}}</b></span>                    
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('learn.catalog.addstep') && activeChapter.lms_chapter_id_external && activeChapter.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">                      
                      <CButton class="d-inline-block m-0"
                               color="primary"
                               @click="openSidebarRight('learn_step_details', { lms_course: activeCourse, lms_chapter: activeChapter, lms_step_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('lms.Add_step')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeChapter.lms_chapter_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !stepsLoading}">
                    <div v-if="stepsLoading">
                      <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                    </div>
                    <div v-else>
                      <b-table ref="stepsTable"
                              class="data_table add_bottom_right_radius"
                              :data="steps"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="stepsPaginated"
                              :per-page="stepsPerPage"
                              :current-page.sync="stepsPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :draggable="true"
                              @dragstart="dragStartStep"
                              @drop="dropStep"
                              @dragover="dragOverStep"
                              @dragleave="dragLeaveStep">
                            
                            <template slot-scope="props">                          
                              <b-table-column field="step_order" :label="$t('Order')" width="5%">
                                <span>
                                  <i v-if="activeChapter.from_marketplace === 'N'" class="fas fa-arrows-alt mr-2 pointer"/>{{props.row.step_order + 1}}
                                </span>
                              </b-table-column>                              
                              <b-table-column field="step_name" :label="$t('common.Step')" :searchable="searchEnabled">
                                <span><b>{{props.row.step_name}}</b></span>
                                <span v-if="props.row.duration" class="d-block meta">
                                  {{props.row.step_type}} | {{props.row.duration}} {{props.row.duration !== 1 ? $t('common.minutes') : $t('common.minute')}}
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="props.row.active === 'N'" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>
                              </b-table-column>
                              <b-table-column field="actions" :visible="checkPermission('learn.catalog.editstep')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary" 
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('learn.Step_managed_by_owner')} : ''"
                                          @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('learn_step_details', { lms_course: props.row.course, lms_chapter: props.row.chapter, lms_step_id_external: props.row.lms_step_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>                                  
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">                                                          
                                <span>{{$t('learn.no_steps_found')}}</span>             
                              </div>                
                            </template> 
                      </b-table>
                    </div>
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('learn.Select_chapter_for_steps')}}</span>
                  </CCol>
                </CRow>           
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>       
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue'

export default {
  name: 'Catalog',
  components: {
    loadingSpinner,
    noPermission,
    targetGroupPopover
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,      
      activeCategory: {
        course_category_name: null,
        lms_course_category_id_external: null,
        from_marketplace: null
      },
      activeCourse: {
        course_name: null,
        lms_course_id_external: null,
        from_marketplace: null
      },
      activeChapter: {
        chapter_name: null,
        lms_chapter_id_external: null,
        from_marketplace: null
      },      
      categories: [],
      categoriesLoading: false,
      categoriesLoaded: false,
      categoriesPaginated: false,
      categoriesPerPage: 8,
      categoriesPage: 1,
      courses: [],
      coursesLoading: false,
      coursesLoaded: false,
      coursesPaginated: false,
      coursesPerPage: 8,
      coursesPage: 1,
      chapters: [],
      chaptersLoading: false,
      chaptersLoaded: false,
      chaptersPaginated: false,
      chaptersPerPage: 8,
      chaptersPage: 1,
      steps: [],
      stepsLoading: false,
      stepsLoaded: false,
      stepsPaginated: false,
      stepsPerPage: 8,
      stepsPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: true,
      showInactive: false,
      videosUploading: []
    }
  },
  methods: {
    filterCatalogData() {  
      this.getCategories();
      
      if(this.activeCategory.course_category_name && this.activeCategory.lms_course_category_id_external && this.activeCategory.from_marketplace) {
        this.getCategoryCourses(this.activeCategory.course_category_name, this.activeCategory.lms_course_category_id_external, this.activeCategory.from_marketplace);
      }

      if(this.activeCourse.course_name && this.activeCourse.lms_course_id_external && this.activeCourse.from_marketplace) {
        this.getCourseChapters(this.activeCourse.course_name, this.activeCourse.lms_course_id_external, this.activeCourse.from_marketplace);
      }

      if(this.activeChapter.chapter_name && this.activeChapter.lms_chapter_id_external && this.activeChapter.from_marketplace) {
        this.getChapterSteps(this.activeChapter.chapter_name, this.activeChapter.lms_chapter_id_external, this.activeChapter.from_marketplace);
      }      
    },       
    getCategories() {
      // Start the loader
      if(!this.categoriesLoaded) this.categoriesLoading = true;
      // Get the categories
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/categories/overview')
      .then(res => {
        this.categories = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.categories = this.categories.filter( i => ['Y'].includes( i.active ) );        
        // Check if the pagination should be enabled
        (this.categories.length > this.categoriesPerPage) ? this.categoriesPaginated = true : this.categoriesPaginated = false;
        // Stop the loader
        this.categoriesLoading = false;
        // Update the categoriesLoaded value
        this.categoriesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getCategoryCourses(categoryName, categoryIdExternal, categoryFromMarketplace) {
      // Check if a different category is selected
      if(this.activeCategory.lms_course_category_id_external !== categoryIdExternal) {
        // Start the loader
        this.coursesLoading = true;      
        // Clear the courses (if necessary), chapters and steps
        this.courses = [];
        this.chapters = [];
        this.steps = [];
        // Update the active data
        this.activeCategory = { course_category_name: categoryName, lms_course_category_id_external: categoryIdExternal, from_marketplace: categoryFromMarketplace };
        this.activeCourse = { course_name: null, lms_course_id_external: null, from_marketplace: null };
        this.activeChapter = { chapter_name: null, lms_chapter_id_external: null, from_marketplace: null };
      }
      // Get the courses
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/category/' + categoryIdExternal + '/courses')
      .then(res => {
        this.courses = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.courses = this.courses.filter( i => ['Y'].includes( i.active ) );
        // Enable the pagination if necessary
        (this.courses.length > this.coursesPerPage) ? this.coursesPaginated = true : this.coursesPaginated = false;        
        // Stop the loader
        this.coursesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getCourseChapters(courseName, courseIdExternal, courseFromMarketplace) {
      // Check if a different course is selected
      if(this.activeCourse.lms_course_id_external !== courseIdExternal) {
        // Start the loader
        this.chaptersLoading = true;
        // Clear the chapters and steps
        this.chapters = [];
        this.steps = [];
        // Update the active data
        this.activeCourse = { course_name: courseName, lms_course_id_external: courseIdExternal, from_marketplace: courseFromMarketplace };
        this.activeChapter = { chapter_name: null, lms_chapter_id_external: null, from_marketplace: null };
      }
      // Get the chapters
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/course/' + courseIdExternal + '/chapters/overview')
      .then(res => {
        this.chapters = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.chapters = this.chapters.filter( i => ['Y'].includes( i.active ) );
        // Stop the loader
        this.chaptersLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getChapterSteps(chapterName, chapterIdExternal, chapterFromMarketplace) {
      // Check if a different chapter is selected
      if(this.activeChapter.lms_chapter_id_external !== chapterIdExternal) {
        // Start the loader
        this.stepsLoading = true;
        // Clear the steps
        this.steps = [];
        // Update the active data
        this.activeChapter = { chapter_name: chapterName, lms_chapter_id_external: chapterIdExternal, from_marketplace: chapterFromMarketplace };
      }
      // Get the steps
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/chapter/' + chapterIdExternal + '/steps')
      .then(res => {
        this.steps = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.steps = this.steps.filter( i => ['Y'].includes( i.active ) );
        // Stop the loader
        this.stepsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    updateCourseChaptersOrder(data) {
      let params = {}
      params = data;
            
      if(this.activeCourse.course_name && this.activeCourse.lms_course_id_external) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/course/' + this.activeCourse.lms_course_id_external + '/chapters/order', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('learn.Chapter_order_updated'), type: 'is-success', duration: 2000 });
          // Update the course details
          this.getCourseChapters(this.activeCourse.course_name, this.activeCourse.lms_course_id_external);
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      }
    },    
    updateChapterStepsOrder(data) {
      let params = {}
      params = data;
      
      if(this.activeChapter.chapter_name && this.activeChapter.lms_chapter_id_external) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/chapter/' + this.activeChapter.lms_chapter_id_external + '/steps/order', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('lms.Step_order_updated'), type: 'is-success', duration: 2000 });
          // Update the course details
          this.getChapterSteps(this.activeChapter.chapter_name, this.activeChapter.lms_chapter_id_external);
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      }
    },     
    copyToClipboard(data) {
      // Set path
      let lmsCoursePath = '/lms/course/' + data.lms_course_id_external;
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = lmsCoursePath;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
      // Show message
      this.$buefy.toast.open({ message: this.$t('common.Copied_path_to_clipboard'), type: 'is-success', duration: 2000 });
    },  
    dragStartChapter(payload) {
      if(this.activeCourse.from_marketplace === 'N') {
        this.draggingRow = payload.row
        this.draggingRowIndex = payload.index
      }
    },
    dragOverChapter(payload) {
      if(this.activeCourse.from_marketplace === 'N') {
        payload.event.target.closest('tr').classList.add('is-selected')
        payload.event.preventDefault()
      }
    },
    dragLeaveChapter(payload) {
      if(this.activeCourse.from_marketplace === 'N') {
        payload.event.target.closest('tr').classList.remove('is-selected')
        payload.event.preventDefault()
      }
    },
    dropChapter(payload) {
      if(this.activeCourse.from_marketplace === 'N') {
        payload.event.target.closest('tr').classList.remove('is-selected')
        const droppedOnRowIndex = payload.index    
        this.draggingRow.new_chapter_order = droppedOnRowIndex;
        this.draggingRow.old_chapter_order = this.draggingRowIndex;      
        this.updateCourseChaptersOrder(this.draggingRow);
      }
    },    
    dragStartStep(payload) {
      if(this.activeChapter.from_marketplace === 'N') {
        this.draggingRow = payload.row
        this.draggingRowIndex = payload.index
      }
    },
    dragOverStep(payload) {
      if(this.activeChapter.from_marketplace === 'N') {
        payload.event.target.closest('tr').classList.add('is-selected')
        payload.event.preventDefault()
      }
    },
    dragLeaveStep(payload) {
      if(this.activeChapter.from_marketplace === 'N') {
        payload.event.target.closest('tr').classList.remove('is-selected')
        payload.event.preventDefault()
      }
    },
    dropStep(payload) {
      if(this.activeChapter.from_marketplace === 'N') {
        payload.event.target.closest('tr').classList.remove('is-selected')
        const droppedOnRowIndex = payload.index    
        this.draggingRow.new_step_order = droppedOnRowIndex;
        this.draggingRow.old_step_order = this.draggingRowIndex;      
        this.updateChapterStepsOrder(this.draggingRow);
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    
    
    this.getPlatformPermissions();
    this.getCategories();

    this.$bus.$on('update_learn_categories', () => {
      this.getCategories();
    });
    
    this.$bus.$on('update_learn_category_courses', (category) => {
      this.getCategoryCourses(category.course_category_name, category.lms_course_category_id_external);
    });
    
    this.$bus.$on('update_learn_course_chapters', (course) => {
      this.getCourseChapters(course.course_name, course.lms_course_id_external);
    });

    this.$bus.$on('update_learn_chapter_steps', (chapter) => {
      this.getChapterSteps(chapter.chapter_name, chapter.lms_chapter_id_external);
    });

    this.$bus.$on('new_learn_video_uploading', (videoExternalId) => {
      // Add the external video id to the videosUploading array
      this.videosUploading.push(videoExternalId);
    })
    
    this.$bus.$on('learn_video_upload_finished', (videoExternalId) => {
      // Remove the external post id from the videosUploading array
      this.videosUploading = this.videosUploading.filter(function(uuid) {
        return uuid != videoExternalId;
      });
    })    
  },
  beforeDestroy() {
    this.$bus.$off('update_learn_categories');
    this.$bus.$off('update_learn_category_courses');
    this.$bus.$off('update_learn_course_chapters');    
    this.$bus.$off('update_learn_chapter_steps');
  }  
}
</script>